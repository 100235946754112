import {
  Box,
  CardActions,
  CardContent,
  Chip,
  Typography
  // chipClasses
} from '@mui/material';
// import { imageFallback } from '../../utils';
import { FeedbackAndPromotions, InfoCard } from './components';
import { ServiceCard } from '../../components';
// import { useNavigate } from 'react-router-dom';
import useHotelInfo from '../HotelInfo/hotel-info-hooks';
import { startCase } from 'lodash';
import EmailIcon from '@mui/icons-material/Email';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import RichTextContent from '../../components/RTEContent';

function NotVerified() {
  const {
    about,
    dir_con,
    phoneNumber,
    propertyDetails,
    hotelRoomsImage,
    directory_description,
    hotelDiningImage,
    directory,
    hotel_rooms,
    aboutHotel,
    hotel_services
  } = useHotelInfo();
  // const navigate = useNavigate();
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        // overflow: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': { display: 'none' }
      }}
    >
      {/* <Box
        sx={{
          px: 2,
          display: 'flex',
          fontWeight: 600,
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: theme => theme.spacing(2.5)
        }}
      >
        Hello
        <Box
          width={36}
          height={36}
          alt="Emoji"
          component="img"
          onError={imageFallback}
          src="/assets/hand-emoji.png"
          sx={{ ml: 0.5, verticalAlign: 'bottom' }}
        />
        <Chip
          variant="filled"
          color="primary"
          label="Chat With Us"
          deleteIcon={<ChatIcon />}
          onClick={() => navigate('/chat')}
          onDelete={() => navigate('/chat')}
          sx={{ fontSize: theme => theme.spacing(1.75) }}
        />
      </Box> */}

      <InfoCard />
      {aboutHotel && (
        <Box sx={{ p: 2 }}>
          <ServiceCard
            showInfo={true}
            image={about?.img as string}
            label={'About Us'}
            description={about?.desc as string}
            notVerified={true}
          >
            <CardContent sx={{ px: 2, pb: 0 }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  mb: 0.5,
                  fontWeight: 600,
                  fontSize: theme => theme.spacing(2.5)
                }}
              >
                {propertyDetails?.getPropertyByDomain &&
                  startCase(propertyDetails?.getPropertyByDomain?.name)}
              </Typography>
              {dir_con?.address?.toString().startsWith('<') ? (
                <RichTextContent content={dir_con?.address?.toString()} />
              ) : (
                <Typography
                  sx={{
                    color: 'text.secondary',
                    textDecorationLine: 'underline'
                  }}
                  variant="body2"
                >
                  {dir_con?.address}
                </Typography>
              )}
            </CardContent>
            <CardActions sx={{ px: 2, py: 2 }}>
              <Chip
                variant="filled"
                // color="primary"
                sx={{
                  flex: 1,
                  bgcolor: 'primary.main',
                  color: 'white'
                  // [`& .${chipClasses.icon}`]: {
                  //   color: 'white'
                  // }
                }}
                label={phoneNumber}
                icon={<WifiCalling3Icon fontSize="small" />}
                onClick={e => {
                  e.preventDefault();
                  phoneNumber && window.open(`tel:${phoneNumber}`);
                }}
              />
              <Chip
                variant="filled"
                // color="primary"
                label={dir_con?.email}
                sx={{
                  flex: 1,
                  overflow: 'hidden',
                  bgcolor: 'primary.main',
                  color: 'white'
                  // [`& .${chipClasses.icon}`]: {
                  //   color: 'white'
                  // }
                }}
                icon={<EmailIcon fontSize="small" />}
                onClick={() =>
                  dir_con?.email && window.open(`mailto:${dir_con.email}`)
                }
              />
            </CardActions>
          </ServiceCard>
        </Box>
      )}
      {hotel_rooms && (
        <Box sx={{ p: 2 }}>
          <ServiceCard
            showInfo={false}
            label={'Hotel Rooms'}
            path={`/hotel-rooms`}
            image={hotelRoomsImage as string}
            notVerified={true}
          />
        </Box>
      )}

      {directory && (
        <Box sx={{ p: 2 }}>
          <ServiceCard
            showInfo={true}
            image={dir_con?.img as string}
            description={directory_description as string}
            label={'Hotel Directory'}
            notVerified={true}
          />
        </Box>
      )}
      {hotel_services && (
        <Box sx={{ p: 2 }}>
          <ServiceCard
            showInfo={false}
            label={'Dining'}
            path={`/dining`}
            image={hotelDiningImage as string}
            notVerified={true}
          />
        </Box>
      )}

      <FeedbackAndPromotions hideFeedback={true} />
    </Box>
  );
}

export default NotVerified;
