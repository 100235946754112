import {
  Box,
  ButtonGroup,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import React from 'react';
import { Paper } from '.';
// import DineInDialog from '../../components/DineInDialog';
// import { ExtraItemDrawer } from '../../components/ExtraItemDrawer';
import AllergenIcon from '../../components/Icons/AllergenIcon';
import { CHILLI_ICONS, ALLERGEN_ICONS } from '../../constant/services';
import { imageFallback } from '../../utils';
import useInfo from '../Dashboard/components/InfoCard';
import { useMenuItem } from './e-menu-hook';
import { DineInItem } from '../../generated/graphql';
import { CustomButton } from '../InRoomDining';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ExtraItemDrawer } from '../../components/ExtraItemDrawer';
import { useCart } from '../../store';
// import { is } from 'date-fns/locale';

interface MenuItemProps {
  item: any;
  isAuthenticated: boolean;
  // currentCategory: string;
  handleOpen: (d: DineInItem) => void;
}

const MenuItemCard = ({ item, handleOpen, isAuthenticated }: MenuItemProps) => {
  const { currency } = useInfo();
  const {
    name,
    price,
    img,
    id,
    isVegan,
    desc,
    allergens,
    // type,
    is_recommended,
    spice_level,
    extraOptions,
    selections
  } = item;

  // const ALL_MENU = 'ALL_MENU';
  const chili = (CHILLI_ICONS as any)[spice_level];
  const [open, setOpen] = React.useState(false);
  // const [Icon, setIcon] = React.useState(false);
  const cart = useCart((state: any) => state.cart);
  const addToCart = useCart((state: any) => state.addToCart);
  const removeFromCart = useCart((state: any) => state.removeFromCart);
  const { ALLERGEN_NAMES } = useMenuItem();
  const itemsInCart = React.useMemo(() => {
    const currentItems = cart.filter((item: any) => item.id === id);
    return currentItems.reduce((acc: number, curr: any) => acc + curr.count, 0);
  }, [cart, id]);

  const toggleDrawer = () => setOpen(state => !state);

  const addToCartWrapper = (id: string) => {
    if (extraOptions?.length || selections?.length) {
      toggleDrawer();
    } else {
      addToCart(id);
    }
  };

  // const handleIconOpen = () => {
  //   setIcon(true);
  // };
  // const hanldleIconClose = () => {
  //   setIcon(false);
  // };
  return (
    <Paper
      sx={{
        mt: 2,
        padding: 1.5,
        display: 'flex'
      }}
    >
      {img ? (
        <Box
          src={img}
          alt={name}
          width={70}
          height={70}
          component="img"
          onError={imageFallback}
          style={{ borderRadius: '10px', objectFit: 'cover' }}
          onClick={() => handleOpen(item)}
        />
      ) : (
        ''
      )}
      <Box sx={{ ml: 1.2, flex: 1 }}>
        <Box sx={{ display: 'flex' }}>
          {is_recommended === true && (
            <Tooltip
              enterTouchDelay={0}
              title="Chef's Recommendation"
              sx={{ mr: 0.5 }}
            >
              <Box
                component="img"
                onError={imageFallback}
                style={{
                  height: '20px',
                  width: '20x',
                  marginLeft: '5px',
                  marginTop: '-4px'
                }}
                src={`/assets/chef.svg`}
              />
            </Tooltip>
          )}
          <Typography variant="body2" sx={{ color: 'text.tile' }}>
            {name}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', marginTop: '5px' }}>
          {desc && (
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', fontStyle: 'italic' }}
              onClick={() => handleOpen(item)}
            >
              {desc.slice(0, 40)}...
            </Typography>
          )}
        </Box>
        <Box sx={{ display: 'flex' }}>
          {allergens?.map((all: any, index: number) => {
            const icon = (ALLERGEN_ICONS as any)[all];
            const name = (ALLERGEN_NAMES as any)[all];
            return icon ? (
              <Tooltip
                enterTouchDelay={0}
                title={name || ''}
                key={index}
                style={{ marginRight: '5px' }}
              >
                <Typography sx={{ pr: 1, mr: -2.5 }}>{icon}</Typography>
              </Tooltip>
            ) : (
              <AllergenIcon source={all} key={index} />
            );
          })}
        </Box>
        <Box sx={{ display: 'flex' }}>
          {isVegan === 'Vegan' && (
            <Tooltip enterTouchDelay={0} title="Vegan">
              <Box
                component="img"
                onError={imageFallback}
                style={{ marginRight: '10px', objectFit: 'contain' }}
                src={`/assets/veg.png`}
              />
            </Tooltip>
          )}
          {isVegan === 'NonVegan' && (
            <Tooltip enterTouchDelay={0} title="NonVegan">
              <Box
                component="img"
                onError={imageFallback}
                style={{ marginRight: '10px', objectFit: 'contain' }}
                src={`/assets/non-veg.png`}
              />
            </Tooltip>
          )}
          <Typography>{chili}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'flex-end'
        }}
      >
        <Typography variant="body2" mr="8px" sx={{ color: 'text.tile' }}>
          {/* <Typography variant="body2" sx={{ marginRight: '-50px', marginTop: '-10px' }}> */}
          {currency} {price}
        </Typography>
        {isAuthenticated &&
          (!Boolean(itemsInCart) ? (
            <CustomButton
              size="small"
              variant="outlined"
              sx={{ alignSelf: 'center' }}
              onClick={() => addToCartWrapper(id)}
            >
              Add
            </CustomButton>
          ) : (
            <ButtonGroup
              size="small"
              variant="contained"
              sx={{
                border: 'none',
                color: '#ffffff',
                alignSelf: 'center',
                backgroundColor: 'primary.main'
              }}
            >
              <IconButton
                size="small"
                onClick={() => removeFromCart(id)}
                sx={{ color: '#ffffff' }}
              >
                <RemoveIcon fontSize="inherit" />
              </IconButton>
              <CustomButton
                size="small"
                disableRipple
                disableFocusRipple
                disableTouchRipple
                sx={{ border: 'none !important' }}
              >
                {itemsInCart}
              </CustomButton>
              <IconButton
                size="small"
                onClick={() => addToCartWrapper(id)}
                sx={{ color: '#ffffff' }}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
            </ButtonGroup>
          ))}
      </Box>

      {isAuthenticated &&
        (item?.extraOptions?.length !== 0 || item?.selections) && (
          <ExtraItemDrawer
            open={open}
            currentItem={item}
            toggleDrawer={toggleDrawer}
          />
        )}
    </Paper>
  );
};

export default MenuItemCard;
