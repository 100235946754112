import create from "zustand";
// import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

let store: any = (set: any) => ({
  id: null,
  setEMenuGuest: (id: string) => {
    set((state: any) => {
      state.id = id;
    });
  },
});

// store = devtools(store);
store = immer(store);

const useEMenuAuth = create(store);

export { useEMenuAuth };
