import React from 'react';

const RichTextContent = ({ content }: { content: string }) => {
  // Modify the <img> tags to retain width/height but make them responsive
  const cleanedContent = content.replace(
    /<img[^>]*\s(width|height)="[^"]*"[^>]*>/g,
    match => {
      // Extract the width and height values
      const widthMatch = match.match(/width="(\d+)"/);
      const heightMatch = match.match(/height="(\d+)"/);
      const width = widthMatch ? widthMatch[1] : 'auto';
      const height = heightMatch ? heightMatch[1] : 'auto';

      // Add responsive styles
      return match.replace(
        /<img/g,
        `<img style="max-width: 100%; height: auto; aspect-ratio: ${width} / ${height};" `
      );
    }
  );

  return (
    <div
      contentEditable="false"
      dangerouslySetInnerHTML={{ __html: cleanedContent }}
    />
  );
};

export default RichTextContent;
