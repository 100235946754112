import * as React from 'react';
import { Box, Chip, Divider, Typography } from '@mui/material';

import { useAuth } from '../../store';
import { FullScreenLoader } from '../../components';
import { HeaderLayout, Layout } from '../InRoomDining';
import { useGetTaskHistoryQuery } from '../../generated/graphql';

function ServiceHistory() {
  const guest = useAuth((state: any) => state.guest);
  const { data: taskHistoryData, loading: loadingTaskHistory } =
    useGetTaskHistoryQuery({
      variables: {
        roomID: guest?.room_id,
        guestID: guest?.guest_id,
        propertyID: guest?.property_id
      },
      skip: !guest?.guest_id
    });
  const taskHistory = taskHistoryData?.getTaskHistory || [];

  const getLabel = (status: any) => {
    switch (status) {
      case 'Open':
        return 'InProgress';
      case 'Unassigned':
        return 'InProgress';
      case 'On-Hold':
        return 'InProgress';
      case 'Escalated':
        return 'InProgress';
      case 'Escalated-Hold':
        return 'InProgress';
      case 'Scheduled':
        return 'InProgress';
      case 'Timeout':
        return 'InProgress';
      case 'Closed':
        return 'Closed';
      case 'Escalated Completed':
        return 'Completed';
      case 'Completed':
        return 'Completed';
      default:
        return 'Cancelled';
    }
  };

  const getWarning = (status: any) => {
    switch (status) {
      case 'Open':
        return 'primary';
      case 'On-Hold':
        return 'primary';
      case 'Escalated':
        return 'primary';
      case 'Escalated-Hold':
        return 'primary';
      case 'Scheduled':
        return 'primary';
      case 'Timeout':
        return 'primary';
      case 'Closed':
        return 'warning';
      case 'Escalated Completed':
        return 'success';
      case 'Completed':
        return 'success';
      case 'Unassigned':
        return 'primary';
      default:
        return 'warning';
    }
  };

  if (loadingTaskHistory) return <FullScreenLoader />;

  return (
    <Layout index={3}>
      <HeaderLayout title="Service History" />
      <Box
        sx={{
          p: 2,
          flex: 1,
          overflow: 'auto',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {taskHistory?.map((service, i) => (
          <React.Fragment key={i}>
            <Box
              sx={{ my: 2, display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, textAlign: 'left' }}
              >
                {service?.task_name?.trim()}
              </Typography>

              {getWarning(service?.status) === 'primary' ? (
                <Chip
                  label={getLabel(service?.status)}
                  size="small"
                  sx={{ bgcolor: 'primary.main', color: 'white' }}
                  variant="filled"
                />
              ) : (
                <Chip
                  label={getLabel(service?.status)}
                  size="small"
                  color={getWarning(service?.status)}
                  variant="filled"
                />
              )}
            </Box>
            <Divider sx={{ my: 1 }} light={true} />
          </React.Fragment>
        ))}
      </Box>
    </Layout>
  );
}

export default ServiceHistory;
