import {
  Box,
  Menu,
  Button,
  styled,
  MenuItem,
  Typography,
  IconButton,
  Paper as MiUiPaper,
  SxProps,
  Theme,
  InputBase
} from '@mui/material';
import * as React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation
} from 'react-router-dom';

import { useCart } from '../../store';
import { startCase, camelCase } from 'lodash';
import useRoomDining from './room-dining-hook';
import { FullScreenLoader } from '../../components';
import useInfo from '../Dashboard/components/InfoCard';
import useHotelService from '../HotelSer/hotel-ser-hooks';
import useHotelInfo from '../HotelInfo/hotel-info-hooks';
import { groupBy } from 'lodash';
import FoodItemList from './FoodItemList';
import { MENU_ITEM_FONT } from '../../constants';
import TaskHistoryProvider from '../../context/TaskHistoryContext';
import NavBar from '../Dashboard/components/NavBar';
import RichTextContent from '../../components/RTEContent';
import FoodItemCard from './FoodItemCard';
const ALL_MENU = 'ALL_MENU';

interface HeaderLayoutProps {
  title: string;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
}

export const HeaderLayout = ({ sx, title, children }: HeaderLayoutProps) => {
  const { logoTileBg, logoTileOpacity } = useInfo();
  const navigate = useNavigate();
  const backgroundStyles = logoTileBg
    ? {
        background: `linear-gradient(rgba(43, 43, 43, ${
          1.0 - (logoTileOpacity || 0.3)
        }), rgba(43, 43, 43, ${
          1.0 - (logoTileOpacity || 0.7)
        })), url(${logoTileBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '0px 0px 30px 30px'
      }
    : {};
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        height: '82px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media(max-width:280px)': {
          flexDirection: 'column'
        },
        boxShadow:
          '0px 8px 10px 0px rgba(0, 0, 0, 0.25), 0px 4px 8px rgba(0, 0, 0, 0.06)',
        ...backgroundStyles,
        ...(sx ? sx : {})
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton disableRipple onClick={() => navigate(-1)}>
          <ArrowBackIcon sx={{ color: 'white' }} />
        </IconButton>
        <Typography
          variant="h6"
          sx={{ cursor: 'pointer', ...MENU_ITEM_FONT }}
          onClick={() => navigate(-1)}
        >
          {title}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};
interface HeaderProps {
  categories: string[];
  currentCategory: string;
  onClick: (category: string) => void;
  headerTitle: string;
}
interface SubCategoryProps {
  subCategories: string[];
  currentCategory: string;
  onClick: (category: string) => void;
}

const Header = (props: HeaderProps) => {
  // Hooks
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Vars
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { categories, currentCategory, onClick, headerTitle } = props;
  const { propertyDetails } = useHotelInfo();
  return (
    <HeaderLayout title={headerTitle}>
      {propertyDetails?.getPropertyByDomain?.show_main_category &&
      categories.length > 1 ? (
        <>
          <Paper sx={{ backgroundColor: 'white', mr: -1.5 }}>
            <Button
              onClick={handleClick}
              sx={{ color: 'text.primary' }}
              endIcon={
                openMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
              }
            >
              {startCase(camelCase(currentCategory))}
            </Button>
          </Paper>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            {categories.map(category => (
              <MenuItem
                key={category}
                onClick={() => {
                  onClick && onClick(category);
                  handleClose();
                }}
                sx={{ backgroundColor: 'white' }}
              >
                {startCase(camelCase(category))}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        ''
      )}
    </HeaderLayout>
  );
};

const SubCategory = (props: SubCategoryProps) => {
  const { hide_all_menu } = useHotelInfo();
  // Hooks
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Vars
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { subCategories, currentCategory, onClick } = props;

  return (
    <>
      <Paper sx={{ backgroundColor: 'white' }}>
        <Button
          onClick={handleClick}
          sx={{ color: 'text.tile' }}
          endIcon={
            openMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
          }
        >
          {startCase(camelCase(currentCategory))}
        </Button>
      </Paper>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {!hide_all_menu && (
          <MenuItem
            key={ALL_MENU}
            onClick={() => {
              onClick && onClick(ALL_MENU);
              handleClose();
            }}
            sx={{ color: 'text.tile', backgroundColor: 'white' }}
          >
            {startCase(camelCase(ALL_MENU))}
          </MenuItem>
        )}
        {subCategories?.map(category => (
          <MenuItem
            key={category}
            onClick={() => {
              onClick && onClick(category);
              handleClose();
            }}
            sx={{ color: 'text.tile', backgroundColor: 'white' }}
          >
            {startCase(camelCase(category))}
          </MenuItem>
        ))}
        {/* <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  const { roomID } = useParams();
  const cart = useCart((state: any) => state.cart);
  const totalItems = cart.reduce(
    (prev: number, current: any) => prev + current.count,
    0
  );

  if (totalItems === 0) return null;

  return (
    <Box sx={{ p: 1 }}>
      <Button
        fullWidth
        variant="contained"
        onClick={() => navigate(`/${roomID}/cart`)}
      >
        View Cart ({totalItems} Items)
      </Button>
    </Box>
  );
};

export const Paper = styled(MiUiPaper)(({ theme }) => ({
  border: '1px solid #F0F0F0',
  boxShadow: 'none',
  borderRadius: theme.spacing(1.875)
  // boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)",
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  paddingTop: '0px !important',
  paddingBottom: '0px !important',
  paddingRight: '4px !important',
  paddingLeft: '4px !important',
  borderRadius: '5px',
  fontSize: '13px'
}));

interface LayoutProps {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  index?: number;
}

// Using position fixed because of chrome mobile's moving url bar's behavior
// https://stackoverflow.com/questions/52848856/100vh-height-when-address-bar-is-shown-chrome-mobile

export const Layout = ({ children, sx, index }: LayoutProps) => {
  const { bgImage, bgOpacity } = useInfo();
  const backgroundStyles = bgImage
    ? {
        background: `linear-gradient(rgba(255,255,255,${
          1 - (bgOpacity || 0)
        }), rgba(255,255,255,${1 - (bgOpacity || 0)})),url(${bgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }
    : { backgroundColor: 'background.light' };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          position: 'fixed',
          top: 0,
          bottom: 0,
          paddingBottom: index ? 9 : 0,
          width: '100%',
          minHeight: 'unset',
          flexDirection: 'column',
          ...backgroundStyles,
          ...(sx ? sx : {})
        }}
      >
        {children}
      </Box>
      {index && (
        <TaskHistoryProvider>
          <NavBar index={index - 1}></NavBar>
        </TaskHistoryProvider>
      )}
    </>
  );
};

// TODO: Extract All component above into a separate folder
const InRoomDining = () => {
  const [searchParams] = useSearchParams();
  const { diningType } = useHotelService();
  const category = searchParams.get('category');
  const [currentCategory, setCurrentCategory] = React.useState(category || '');
  const [search, setSearch] = React.useState<string>('');

  const { loading, foodMenu, categories, Sections, defaultCategory } =
    useRoomDining();
  const { background_color } = useInfo();

  const [currentSubCategory, setCurrentSubCategory] = React.useState('');

  const menuNames = React.useMemo(() => {
    return diningType === 'Category' ? [...categories] : [...Sections];
  }, [diningType, categories, Sections]);

  const subMenuNames = React.useMemo(() => {
    return diningType === 'Section' ? [...categories] : [...categories];
  }, [diningType, categories]);

  const foodCategories = menuNames;
  const [subCategories, setSubCategories] = React.useState<string[]>([
    ...subMenuNames
  ]);
  let DefaultSubCategory = defaultCategory(currentCategory);
  const location = useLocation();

  React.useMemo(() => {
    if (categories && DefaultSubCategory && currentCategory) {
      if (diningType === 'Category') {
        setCurrentSubCategory(category || '');
      } else {
        setCurrentSubCategory(DefaultSubCategory);
      }
    }
  }, [currentCategory, categories, DefaultSubCategory, diningType, category]);

  const hasRunForCategory = React.useRef(false);
  React.useEffect(() => {
    const updateSubMenuCategories = () => {
      if (currentCategory && foodMenu && subMenuNames) {
        const filteredMenu = foodMenu.find(
          (item: any) => item.name === currentCategory
        );

        if (filteredMenu) {
          const subMenuFilterList = subMenuNames.filter(subCategoryItem => {
            return filteredMenu.items?.find(
              (i: any) => i.type === subCategoryItem
            );
          });

          const shouldUpdateSubCategories =
            subCategories.length !== subMenuFilterList.length;

          if (shouldUpdateSubCategories) {
            const subMenuItems = subMenuNames.filter(subCategoryItem => {
              return filteredMenu.items?.find(
                (i: any) => i.type === subCategoryItem
              );
            });

            setSubCategories([...subMenuItems]);
          }
          if (diningType === 'Category' && !hasRunForCategory.current) {
            hasRunForCategory.current = true;
            setSubCategories([...subMenuFilterList]);
          }
        }
      }
    };

    updateSubMenuCategories();
  }, [currentCategory, subMenuNames, foodMenu, subCategories, diningType]);

  const filteredMenu = React.useMemo(() => {
    if (currentSubCategory === ALL_MENU)
      return foodMenu?.filter((item: any) => item.name === currentCategory);

    let subFilterItems = foodMenu?.map((item: any) => {
      if (item.name === currentCategory) {
        let filterItem = item.items?.filter(
          (items: any) => items.type === currentSubCategory
        );
        return { ...item, items: filterItem };
      }
      return {};
    });
    return subFilterItems?.filter(value => {
      return value !== undefined && Object.keys(value).length > 0;
    });
  }, [currentCategory, currentSubCategory, foodMenu]);

  if (loading) return <FullScreenLoader />;

  return (
    <Layout>
      <Header
        categories={foodCategories}
        onClick={setCurrentCategory}
        currentCategory={currentCategory}
        headerTitle={(location.state as string) || 'In Room Dining'}
      />
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          padding: '10px',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {/* Header */}

        {filteredMenu?.map((menu: any, foodMenuIndex: any) => {
          return (
            <React.Fragment key={menu.name}>
              <Box
                sx={{
                  paddingTop: '10px',
                  position: 'sticky',
                  top: '-3px',
                  mt: foodMenuIndex === 0 ? 1 : 3,
                  mb: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  zIndex: 1,
                  backgroundColor: background_color,
                  borderRadius: '5px'
                }}
              >
                <Typography
                  sx={{ fontWeight: 'bold', padding: '5px 0px 0px 10px' }}
                  color="text.tile"
                  variant="body2"
                >
                  {startCase(camelCase(menu.name))}
                </Typography>
                <SubCategory
                  subCategories={subCategories}
                  onClick={setCurrentSubCategory}
                  currentCategory={currentSubCategory}
                />
                {/* <Typography variant="body2">
                  {menu?.items?.length} Items
                </Typography> */}
              </Box>
              <InputBase
                placeholder="Search item..."
                onChange={(e: any) => setSearch(e.target.value)}
                sx={{
                  p: 1.5,
                  width: '100%',
                  borderRadius: 1.25,
                  backgroundColor: '#fff',
                  color: 'text.tile'
                }}
              />
              {menu?.description?.toString().startsWith('<') ? (
                <RichTextContent content={menu?.description?.toString()} />
              ) : (
                <Typography sx={{ mb: 1 }} variant="body2">
                  {menu?.description}
                </Typography>
              )}
              {/* <Typography sx={{ mb: 1 }} variant="body2">
                {menu.description}
              </Typography> */}
              <Box>
                {Object.entries(groupBy(menu?.items, 'type'))
                  .sort(
                    (a, b) =>
                      categories.indexOf(a[0]) - categories.indexOf(b[0])
                  )
                  .map(([type, items]) => {
                    const filteredItems = items
                      ?.sort((a, b) => (a.sequence || 0) - (b.sequence || 0))
                      ?.filter((menu: any) =>
                        menu.name?.toLowerCase().includes(search?.toLowerCase())
                      );
                    return (
                      <React.Fragment key={type}>
                        <Typography
                          sx={{ fontWeight: 'bold', mt: 1, mb: 0 }}
                          variant="body2"
                        >
                          {filteredItems.length > 0 &&
                            startCase(camelCase(type))}
                        </Typography>{' '}
                        {/* Render the type as a heading */}
                        {/* {items
                          ?.sort(
                            (a, b) => (a.sequence || 0) - (b.sequence || 0)
                          )
                          ?.filter((menu: any) =>
                            menu.name
                              ?.toLowerCase()
                              .includes(search?.toLowerCase())
                          )
                          ?.map(item => (
                            <FoodItemCard
                              key={item.id}
                              item={item}
                              currentSubCategory={currentSubCategory}
                            />
                          ))} */}
                        {items.length < 100 ? (
                          filteredItems.map((item: any, i) => (
                            <FoodItemCard
                              key={item.id}
                              item={item}
                              currentSubCategory={currentSubCategory}
                            />
                          ))
                        ) : (
                          <FoodItemList
                            items={filteredItems}
                            search={search}
                            currentSubCategory={currentSubCategory}
                          />
                        )}
                        {/* <FoodItemList
                          items={filteredItems}
                          search={search}
                          currentSubCategory={currentSubCategory}
                        /> */}
                      </React.Fragment>
                    );
                  })}
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
      <Footer />
    </Layout>
  );
};

export default InRoomDining;
