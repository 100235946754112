import React from 'react';
import { VariableSizeList as List } from 'react-window';
import FoodItemCard from './FoodItemCard';

const ItemRenderer = ({
  index,
  style,
  data
}: {
  index: number;
  style: any;
  data: any;
}) => {
  const { items, setSize, currentSubCategory } = data;
  const item = items?.[index];

  return (
    <div style={style}>
      <FoodItemCard
        key={item.id}
        item={item}
        currentSubCategory={currentSubCategory}
        index={index}
        setSize={setSize}
      />
    </div>
  );
};

const FoodItemList = ({
  items,
  search,
  currentSubCategory
}: {
  items: any;
  search: string;
  currentSubCategory: string;
}) => {
  const sortedAndFilteredItems =
    items
      ?.sort(
        (a: { sequence: any }, b: { sequence: any }) =>
          (a.sequence || 0) - (b.sequence || 0)
      )
      ?.filter((menu: { name: string }) =>
        menu.name?.toLowerCase().includes(search?.toLowerCase())
      ) || [];
  const [listHeight, setListHeight] = React.useState(0);
  const listRef = React.useRef<any>(null);
  const sizeMap = React.useRef<{ [key: number]: number }>({});

  const setSize = (index: number, size: number) => {
    if (sizeMap.current[index] !== size) {
      sizeMap.current[index] = size;
      listRef.current?.resetAfterIndex(index); // Recalculate layout
      const totalHeight = Object.values(sizeMap.current).reduce(
        (acc, curr) => acc + curr,
        0
      );
      setListHeight(totalHeight);
    }
  };

  const getSize = (index: number) => sizeMap.current[index] || 125;

  return (
    <List
      ref={listRef}
      height={listHeight || 700}
      itemCount={sortedAndFilteredItems.length}
      itemSize={getSize}
      width="100%"
      itemData={{ items: sortedAndFilteredItems, currentSubCategory, setSize }}
      style={{
        overflow: 'hidden'
      }}
    >
      {ItemRenderer}
    </List>
  );
};

export default FoodItemList;
